import { isHidden } from '../utilities/functions';

export default class MobileMenu {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.navBlock = elem;
        this.navToggle = document.querySelector('.mobile-nav-toggle');
        this.navLinks = this.navBlock.querySelectorAll('.site-nav-top-level-link.has-subnav');
        this.breakpointMobile = document.querySelector('.trigger-size.mobile');
        this.breakpointMobileLarge = document.querySelector('.trigger-size.mobile-large');

        this.navLinks = Array.prototype.slice.call(this.navLinks);

        this.init();
    }

    init() {
        this.navToggle.classList.add('first-load');

        this.navToggle.addEventListener('click', () => {
            console.log('Click mobile button');

            if (this.navToggle.classList.contains('active')) {
                this.navBlock.setAttribute('aria-hidden', true);
                this.navToggle.classList.remove('active');
                this.navBlock.classList.remove('active');
                this.navToggle.classList.remove('first-load');
            } else {
                this.navBlock.setAttribute('aria-hidden', false);
                this.navToggle.classList.add('active');
                this.navBlock.classList.add('active');
            }
        });

        this.navLinks.forEach(link => {
            link.addEventListener('click', e => {
                const subNav = link.nextElementSibling;

                if ((isHidden(this.breakpointMobile) == 'block' || isHidden(this.breakpointMobileLarge) == 'block') && subNav) {
                    if (link.classList.contains('subnav-active')) {
                        e.preventDefault();
                        // close all open sub menus first
                        this.navLinks.forEach(link => {
                            link.classList.remove('subnav-active');
                            subNav.setAttribute('aria-hidden', true);
                        });
                    } else {
                        e.preventDefault();
                        // close all open sub menus first
                        this.navLinks.forEach(link => {
                            link.classList.remove('subnav-active');
                            subNav.setAttribute('aria-hidden', true);
                        });

                        link.classList.add('subnav-active');
                        subNav.setAttribute('aria-hidden', false);
                    }
                }
            });
        });
    }
}
