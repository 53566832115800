export default class EventApplication {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.form = elem;
        this.eventEntryFeeInput = document.getElementById('evententryfee');
        this.inMemory = document.getElementById('inmemory');
        this.dobDay = document.getElementById('dobday');
        this.willRaise = document.getElementById('willraise');
        this.conferenceType = document.querySelector('.is-conference');

        this.init();
    }

    init() {
        console.log('init', this.inMemory);
        if (this.eventEntryFeeInput && this.eventEntryFeeInput.classList.contains('select-input')) {
            this.checkEntryFee();
        }

        if (this.inMemory) {
            this.checkInMemory();
        }

        if (this.dobDay) {
            this.checkDob();
        }

        if (this.willRaise) {
            this.checkWillRaise();
        }

        if (this.conferenceType) {
            this.checkConferenceType();
        }
    }

    checkEntryFee() {
        let currentValue = 0;
        let currentMinTarget = 0;

        eventEntryFeeInput.addEventListener('change', e => {
            if (currentValue != eventEntryFeeInput.options[eventEntryFeeInput.selectedIndex]) {
                currentValue = eventEntryFeeInput.options[eventEntryFeeInput.selectedIndex].value;
                currentMinTarget = eventEntryFeeInput.options[eventEntryFeeInput.selectedIndex];
                currentMinTarget = currentMinTarget.getAttribute('data-mintarget');

                if (isNaN(currentMinTarget)) {
                    currentMinTarget = 0;
                }

                willRaise.setAttribute('min', currentMinTarget);
                willRaise.setAttribute('placeholder', currentMinTarget);
            }
        });
    }

    checkInMemory() {
        const inMemory = document.getElementById('inmemory');
        const inMemoryBlock = document.getElementById('in-memory-block');
        const inMemoryName = document.getElementById('in-memory-name');
        const inMemoryRelation = document.getElementById('in-memory-relationship');
        const specialFundBlock = document.getElementById('special-fund-block');
        const specialFundName = document.getElementById('special-fund-name');
        let currentValue = '';

        const inMemoryBlockDisplay = show => {
            if (show) {
                inMemoryBlock.classList.add('active');
                document.getElementById('inmemoryname').setAttribute('required', 'required');
                document.getElementById('inmemoryrelationship').setAttribute('required', 'required');
                inMemoryName.classList.remove('field-required-off');
                inMemoryRelation.classList.remove('field-required-off');
            } else {
                inMemoryBlock.classList.remove('active');
                document.getElementById('inmemoryname').removeAttribute('required');
                document.getElementById('inmemoryrelationship').removeAttribute('required');
                inMemoryName.classList.add('field-required-off');
                inMemoryRelation.classList.add('field-required-off');
            }
        };

        const specialFundBlockDisplay = show => {
            if (show) {
                specialFundBlock.classList.add('active');
                document.getElementById('specialfundname').setAttribute('required', 'required');
                specialFundName.classList.remove('field-required-off');
            } else {
                specialFundBlock.classList.remove('active');
                document.getElementById('specialfundname').removeAttribute('required');
                specialFundName.classList.add('field-required-off');
            }
        };

        this.inMemory.addEventListener('change', () => {
            if (currentValue != inMemory.options[inMemory.selectedIndex]) {
                currentValue = inMemory.options[inMemory.selectedIndex].value;

                if (currentValue === 'inmemory' || currentValue === 'incelebration') {
                    inMemoryBlockDisplay(true);
                    specialFundBlockDisplay(false);
                } else if (currentValue === 'specialfund') {
                    inMemoryBlockDisplay(false);
                    specialFundBlockDisplay(true);
                } else {
                    inMemoryBlockDisplay(false);
                    specialFundBlockDisplay(false);
                }
            }
        });
    }

    checkDob() {
        const dobDay = document.getElementById('dobday');
        const dobMonth = document.getElementById('dobmonth');
        const dobYear = document.getElementById('dobyear');
        const dateOfBirthFields = [dobDay, dobMonth, dobYear];

        dateOfBirthFields.forEach(field => {
            field.addEventListener('input', e => {
                const maxLength = field.getAttribute('data-maxlength');
                const currentValue = field.value;
                let currentValueLength = currentValue.length;
                const nextField = field.nextElementSibling;

                if (currentValueLength >= maxLength) {
                    if (nextField) {
                        nextField.focus();
                    } else {
                        currentValueLength = field.value.length;
                        field.value = field.value.substring(0, maxLength);
                    }
                }
            });
        });
    }

    checkWillRaise() {
        this.willRaise.addEventListener('blur', e => {
            const thisVal = parseInt(this.willRaise.value, 10);
            const minVal = parseInt(this.willRaise.getAttribute('min'), 10);
            const isNotNumber = isNaN(thisVal);

            if (isNotNumber) {
                alert('Value is not a number');
            }

            if (thisVal < minVal) {
                alert('Value does not meet minimum requirements');
            }
        });
    }

    checkConferenceType() {
        const adultTickets = document.getElementById('numticketsadult');
        const childTickets = document.getElementById('numticketschild');
        const adultNames = document.getElementById('adultnames');
        const childNames = document.getElementById('childnames');

        adultTickets.addEventListener('change', e => {
            const adultVal = parseInt(adultTickets.value, 10);
            let adultNamesBlocks = adultNames.querySelectorAll('div');

            adultNamesBlocks = Array.prototype.slice.call(adultNamesBlocks);

            if (adultVal > 1) {
                adultNamesBlocks.forEach(adultNameBlock => {
                    adultNameBlock.classList.remove('active');
                });

                const sliceEnd = adultVal - 1;
                const requiredAdultNamesBlocks = adultNamesBlocks.slice(0, sliceEnd);

                requiredAdultNamesBlocks.forEach(requiredBlock => {
                    requiredBlock.classList.add('active');
                });

                adultNames.classList.add('active');
            } else {
                childNames.classList.remove('active');

                adultNamesBlocks.forEach(adultNameBlock => {
                    adultNameBlock.classList.remove('active');
                });
            }
        });

        childTickets.addEventListener('change', e => {
            const childVal = childTickets.value;
            let childNamesBlocks = childNames.querySelectorAll('div');

            childNamesBlocks = Array.prototype.slice.call(childNamesBlocks);

            if (childVal > 0) {
                childNamesBlocks.forEach(childNameBlock => {
                    childNameBlock.classList.remove('active');
                });

                const sliceEnd = childVal;
                const requiredChildNamesBlocks = childNamesBlocks.slice(0, sliceEnd);

                requiredChildNamesBlocks.forEach(requiredBlock => {
                    requiredBlock.classList.add('active');
                });

                childNames.classList.add('active');
            } else {
                childNames.classList.remove('active');

                childNamesBlocks.forEach(childNameBlock => {
                    childNameBlock.classList.remove('active');
                });
            }
        });
    }
}
