/**
 * Custom Lazy Load component for SVGs in Shine a Star campaign
 *
 * Template usage: https://www.bcrt.org.uk/manager/?a=element/template/update&id=71
 * Relevant classes & data-src attribute are found on <svg> and <image> tags
 *
 * @author Matt Haynes <matt.haynes@joipolloi.com>
 * @package BCRT - Shine a star
 */

const CustomLazyLoad = () => {
    const shineastarPage = document.getElementById('shineastarPage');
    if (!shineastarPage) return;

    const init = () => {
        const starSVGs = Array.from(document.querySelectorAll('.starSVG .starSVG-Image'));

        starSVGs.forEach(star => {
            const dataSrc = star.dataset.src;
            if (dataSrc) {
                star.setAttribute('xlink:href', dataSrc);
            }
        });
    };

    const readyStateCheckInterval = setInterval(() => {
        if (document.readyState === 'complete') {
            clearInterval(readyStateCheckInterval);
            init();
        }
    }, 100);
};

export default CustomLazyLoad;
