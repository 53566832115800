import { requiredFieldsCheck } from '../utilities/functions';

export default class RequiredFields {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.form = elem;
        this.requiredInputs = this.form.querySelectorAll('[required]');
        this.init();
    }

    init() {
        if (this.requiredInputs.length > 0) {
            this.form.addEventListener('submit', e => {
                e.preventDefault();

                const formComplete = requiredFieldsCheck(this.form);

                if (formComplete) {
                    this.form.submit();
                }
            });
        }
    }
}
