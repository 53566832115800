export default class PrintAndShare {
    constructor(printElem, twitterElem, facebookElem) {
        if (!printElem || !twitterElem || !facebookElem) {
            return;
        }

        this.printButton = printElem;
        this.twitterSharer = twitterElem;
        this.facebookSharer = facebookElem;

        this.init();
    }

    init() {
        if (this.printButton) {
            this.printButton.addEventListener('click', () => {
                window.print();
            });
        }

        if (this.twitterSharer) {
            this.twitterSharer.addEventListener('click', () => {
                this.twitterShare();
            });
        }

        if (this.facebookSharer) {
            this.facebookSharer.addEventListener('click', () => {
                this.facebookShareSimple();
            });
        }
    }

    twitterShare() {
        const tweetText = this.twitterSharer.dataset.text;
        const tweetUrl = this.twitterSharer.dataset.url;
        const tweetHashtags = this.twitterSharer.dataset.hashtags;
        const tweetVia = this.twitterSharer.dataset.via;
        const tweetRelated = this.twitterSharer.dataset.related;
        const shareText = `text=${encodeURIComponent(tweetText)}`;
        const shareUrl = `&url=${encodeURIComponent(tweetUrl)}`;
        const shareHashtags = `&hashtags=${encodeURIComponent(tweetHashtags)}`;
        const shareVia = `&via=${tweetVia}`;
        const shareRelated = `&related=${encodeURIComponent(tweetRelated)}`;
        const intentLink = `https://twitter.com/intent/tweet?${shareText + shareUrl + shareHashtags + shareVia + shareRelated}`;

        window.open(intentLink, 'Share', 'width=640,height=400,scrollbars=1,menubar=0,status=0,toolbar=0');
    }

    facebookShareSimple() {
        const link = `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(link, 'Share', 'width=560,height=400,scrollbars=1,menubar=0,status=0,toolbar=0');
    }
}
