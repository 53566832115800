import { isHidden } from '../utilities/functions';

//----------------------------------------------------
//      based on a codepen i found:
//      https://codepen.io/EliFitch/pen/Cobzr
//----------------------------------------------------
export default class StickyNav {
    constructor(elem) {
        if (!elem) {
            return false;
        }

        this.stickyNav = elem;
        this.stickyNavFake = document.getElementById('site-nav-fake');
        this.stuck = false;
        this.stickPoint = this.getDistance();
        this.breakpointMobile = document.querySelector('.trigger-size.mobile'),
        this.breakpointMobileLarge = document.querySelector('.trigger-size.mobile-large');

        this.init();
    }

    init() {
        window.onscroll = e => {
            if ((isHidden(this.breakpointMobile) === 'none' || this.breakpointMobile === undefined) && (isHidden(this.breakpointMobileLarge) === 'none' || this.breakpointMobileLarge === undefined)) {
                const distance = this.getDistance() - window.pageYOffset;
                const offset = window.pageYOffset;

                if ((distance <= 0) && !this.stuck) {
                    this.stickyNav.classList.add('stuck');
                    this.stickyNavFake.classList.add('show');
                    this.stuck = true;
                } else if (this.stuck && (offset <= this.stickPoint)) {
                    this.stickyNav.classList.remove('stuck');
                    this.stickyNavFake.classList.remove('show');
                    this.stuck = false;
                }
            }
        };
    }

    getDistance() {
        const topDist = this.stickyNav.offsetTop;
        return parseInt(topDist, 10);
    }
}
