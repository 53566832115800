/**
 * Some basic pagination for the star container columns to prevent HTML waterfall
 * taking forever when there are a lot of stars on the site
 *
 *
 * @author Matt Haynes <matt.haynes@joipolloi.com>
 * @package BCRT - Shine a star
 */

const ColumnPaginate = () => {
    const shineastarPage = document.getElementById('shineastarPage');
    if (!shineastarPage) return;

    const stars = Array.from(document.querySelectorAll('.x2020x__stars--star'));
    const allStarsRight = document.getElementById('allStarsRight');
    const searchStarInput = document.getElementById('searchStarInput');
    let page = 15;

    const setDisplay = () => {
        page += 15;
        stars.forEach((star, index) => {
            if (index > page) {
                return;
            }
            star.style.display = 'block';
        });
    };

    setDisplay();
    allStarsRight.addEventListener('click', () => {
        if (searchStarInput.value === '') {
            setDisplay();
        }
    });
};

export default ColumnPaginate;
