// import 'babel-polyfill';
import 'nodelist-foreach-polyfill';
import zenscroll from 'zenscroll';
import './custom-file-input';
import 'whatwg-fetch';
import LazyLoad from 'vanilla-lazyload';
import CalculateTrueHeight from '@mattgh9152/calculatetrueheight';
import ToggleStarOverlay from './ToggleStarOverlay';

const ShineStarMain = () => {
    const shineastarPage = document.getElementById('shineastarPage');
    const starGridContainer = document.querySelector('.x2020x__stars');
    if (!shineastarPage) return;

    const CalculateTrueWindowWidth = () => {
        const root = document.documentElement;
        const trueWindowWidth = document.body.clientWidth;

        root.style.setProperty('--vw', `${trueWindowWidth}px`);
        root.style.setProperty('--vwThird', `${trueWindowWidth * 0.333}px`);
    };

    const checkIfMobile = () => {
        const windowWidth = window.innerWidth;
        let isMobile = windowWidth < 768;

        if (windowWidth < 768) {
            isMobile = true;
        }

        return isMobile;
    };

    CalculateTrueHeight();
    CalculateTrueWindowWidth();
    checkIfMobile();

    window.addEventListener('resize', () => {
        checkIfMobile();
        // CalculateTrueHeight();
        CalculateTrueWindowWidth();
    });

    // lazy load images
    const myLazyLoad = new LazyLoad();
    myLazyLoad.update();

    // Scroll actions
    // Header + Top section
    const largeStarImage = document.getElementById('largeImage');
    const headerBar = document.querySelector('.site-header');
    const shineStarLogoContainer = document.querySelector(
        '.shineastar .shineastar-logo',
    );
    const shineStarLogoContainerLarge = document.querySelector(
        '.shineastar .shineastar-logo .shineastar-logo-container',
    );
    const shineStarLogoContainerLogo = document.querySelector(
        '.shineastar .shineastar-logo .main-logo',
    );
    let transformCount = 0;
    let translateX = document.body.clientWidth * 0.45;

    let windowScrolled = window.pageYOffset;

    window.addEventListener('scroll', () => {
        windowScrolled = window.pageYOffset;

        if (windowScrolled > 0) {
            headerBar.classList.add('scrolled');

            shineStarLogoContainerLarge.style.backgroundPosition = `center -${windowScrolled}px`;
            shineStarLogoContainer.style.backgroundPosition = `0% -${
                windowScrolled * 1.5
            }px, 100% -${windowScrolled * 5}px`;
        } else {
            headerBar.classList.remove('scrolled');

            shineStarLogoContainerLarge.style.backgroundPosition = 'center bottom';
            shineStarLogoContainer.style.backgroundPosition = `0% -${
                windowScrolled * 1.5
            }px, 100% -${windowScrolled * 5}px`;
        }

        if (windowScrolled > 200) {
            shineStarLogoContainerLogo.style.opacity = 1;
        } else {
            shineStarLogoContainerLogo.style.opacity = 0;
        }
    });

    const headerDonate = document.getElementById('headerDonate');
    const donateSection = document.getElementById('donate-section');
    if (headerDonate) {
        headerDonate.addEventListener('click', e => {
            e.preventDefault();
            zenscroll.center(donateSection);
        });
    }

    // allstar-section
    const donateScroll = document.getElementById('donateScroll');
    donateScroll.addEventListener('click', () => {
        zenscroll.center(donateSection);
    });

    // Top section animations & scrolls
    const shineStarButton = document.getElementById('shineStarNow');
    const formSection = document.getElementById('form-section');
    const seeAllStars = document.getElementById('seeAllStars');
    const allStarSection = document.getElementById('allstar-section');

    if (shineStarButton) {
        shineStarButton.addEventListener('click', e => {
            e.preventDefault();
            zenscroll.center(formSection);
        });
    }

    const allStarsTransform = document.getElementById('allStarsTransform');
    if (seeAllStars) {
        seeAllStars.addEventListener('click', e => {
            e.preventDefault();
            zenscroll.center(allStarsTransform);
        });
    }

    const largeImage = document.getElementById('largeImage');
    const largeImageSvg = document.getElementById('largeImageSvg');
    if (largeImage) {
        largeImage.addEventListener('click', e => {
            e.preventDefault();
            if (window.getComputedStyle(largeImageSvg).display === 'block') {
                ToggleStarOverlay();
            } else {
                zenscroll.center(formSection);
            }
        });
    }

    // Form Controls
    const nextButton = document.getElementById('formNext');
    const backButton = document.getElementById('formBack');
    const formButtonsContainer = document.querySelector('.buttons');
    const formWrapper = document.getElementById('formWrapper');
    const formFieldsets = document.querySelectorAll('.form-section');
    const formFieldsetsArr = [...formFieldsets];
    const formSteps = document.querySelectorAll('.form-step');
    const formStepsArr = [...formSteps];
    const formOutput = document.querySelectorAll('.form-output-field');
    const formOutputArr = [...formOutput, document.querySelector('.section-5')];
    const largeImageAllStar = document.getElementById('backofstarLarge');
    const noDonation = document.getElementById('nodonation');
    const largeImageForName = document.querySelector('.large-image span.name');
    const starOverlay = document.getElementById('starOverlay');

    console.log('formOutputArr: ', formOutputArr);

    let activeIndex = 0;

    const formName = document.getElementById('pagetitle');
    const formMessage = document.getElementById('content');
    const formFromName = document.getElementById('formFromName');

    const enableNext = () => {
        nextButton.removeAttribute('disabled');
    };

    // Enable Next button
    const nextEventEmitters = document.querySelectorAll(
        '#content,#pagetitle,#formFromName',
    );
    nextEventEmitters.forEach(element => {
        if (!element) return;
        element.addEventListener('input', () => {
            enableNext();
        });
    });

    const multiFieldForm = document.querySelectorAll(
        '.form-section.section-4 input.enableNext',
    );
    const multiFieldFormArr = [...multiFieldForm];
    const expression = /\S+@\S+\.\S/; // Regex expression to match foo@bar.foo
    const enableNextMulti = () => {
        let count = 0;
        multiFieldFormArr.forEach((field, index) => {
            if (field.value !== '') {
                count += 1;
            }
            if (index === 2) {
                if (expression.test(String(field.value).toLowerCase())) {
                    count += 1;
                } else {
                    // TODO: Could put some feedback here
                    //  saying that we require a valid email address
                }
            }
        });
        if (count === 4) {
            nextButton.removeAttribute('disabled');
        } else {
            nextButton.setAttribute('disabled', true);
        }
    };

    // const nextEventEmitters = document.querySelectorAll( '#content,#pagetitle,#formFromName' );
    multiFieldForm.forEach(element => {
        if (!element) return;
        element.addEventListener('input', () => {
            enableNextMulti();
        });
    });

    // Next Button
    if (nextButton) {
        nextButton.addEventListener('click', e => {
            zenscroll.center(formSection);
            e.preventDefault();

            transformCount = 0;
            const formNameVal = formName.value !== '' ? `<h3>${formName.value}</h3>` : '';
            const formMessageVal = formMessage.value !== '' ? `<p>${formMessage.value}</p>` : '';
            const formFromNameVal = formFromName.value !== ''
                ? `<h4>${formFromName.value}</h4>`
                : '';
            const formMessageWhole = `${formNameVal || ''} ${
                formMessageVal || ''
            } ${formFromNameVal || ''}`;
            nextButton.setAttribute('disabled', true);
            // eslint-disable-next-line array-callback-return
            formFieldsetsArr.some((fieldset, index) => {
                const moveForward = () => {
                    console.log('move foward');
                    // Creating the message variables
                    fieldset.classList.remove('active'); // Remove active class from current FIELDSET item
                    formStepsArr[activeIndex].classList.remove('active'); // Remove active class from current progress indicator item
                    if (
                        formWrapper.classList.contains(
                            `fieldset-${activeIndex}-active`,
                        )
                    ) {
                        formWrapper.classList.remove(
                            `fieldset-${activeIndex}-active`,
                        );
                    }

                    if (
                        formSection.classList.contains(
                            `fieldset-${activeIndex}-active_WRAP`,
                        )
                    ) {
                        formSection.classList.remove(
                            `fieldset-${activeIndex}-active_WRAP`,
                        );
                    }

                    if (
                        formButtonsContainer.classList.contains(
                            `buttons-${activeIndex}-active`,
                        )
                    ) {
                        formButtonsContainer.classList.remove(
                            `buttons-${activeIndex}-active`,
                        );
                    }

                    formOutputArr[activeIndex].classList.remove('active');

                    if (formFieldsetsArr[activeIndex + 1]) {
                        formFieldsetsArr[activeIndex + 1].classList.add(
                            'next-active',
                        );
                    }

                    activeIndex = index += 1; // Increment index to fade in the next step
                    formButtonsContainer.classList.add(
                        `buttons-${activeIndex}-active`,
                    );

                    setTimeout(() => {
                        // Display next fieldset in array
                        if (formFieldsetsArr[activeIndex]) {
                            formFieldsetsArr[activeIndex].classList.add(
                                'active',
                            );
                        }

                        if (formFieldsetsArr[activeIndex]) {
                            formFieldsetsArr[activeIndex].classList.remove(
                                'next-active',
                            );
                        }

                        // Display correct progress indicator
                        if (formStepsArr[activeIndex]) {
                            formStepsArr[activeIndex].classList.add('active');
                        }
                        formSection.classList.add(
                            `fieldset-${activeIndex}-active_WRAP`,
                        );
                        formWrapper.classList.add(
                            `fieldset-${activeIndex}-active`,
                        );

                        // Add class to relevant message container
                        if (formOutputArr[activeIndex]) {
                            formOutputArr[activeIndex].classList.add('active');
                        }

                        console.log('activeIndex: ', activeIndex);
                        console.log('formMessageWhole: ', formMessageWhole);

                        if (activeIndex !== 3) {
                            // Add content to relevant message container
                            if (formOutputArr[activeIndex]) {
                                formOutputArr[activeIndex].innerHTML = formMessageWhole;
                            }
                            largeImageAllStar.innerHTML = `<div class="inside">${formMessageWhole}</div>`;
                            starOverlay.innerHTML = `<div class="starOverlay__Inner">${formMessageWhole}</div>`;
                            largeImageForName.innerHTML = formName.value;
                        }
                    }, 500);
                };

                // Matches if current item contains active class
                // and it is not the last item in the array
                if (
                    fieldset.classList.contains('active')
                    && activeIndex !== formFieldsetsArr.length - 1
                ) {
                    moveForward();
                    return true;
                }
                if (activeIndex === formFieldsetsArr.length - 4) {
                    nextButton.removeAttribute('disabled');
                }

                // if (
                //     activeIndex === formFieldsetsArr.length - 3 &&
                //     nextButton.innerHTML !==
                //         '<span class="text">Shine my star</span>'
                // ) {
                //     nextButton.innerHTML =
                //         '<span class="text">Shine my star</span>'; // Change "next" button wording for the final submit stage
                // }

                // If "next" button is actually the final submit stage
                // Apply class for final stage
                if (activeIndex === formFieldsetsArr.length - 2) {
                    // nextButton.onclick = formSubmit();
                    formSection.classList.remove('fieldset-4-active_WRAP');
                    formSection.classList.add('final-stage');
                    transformCount = 0;

                    if (checkIfMobile()) {
                        const largeImageSvgCopy = document
                            .getElementById('largeImageSvg')
                            .cloneNode(true);

                        const div = document.createElement('div');
                        div.classList.add('x2020x__stars--star');

                        div.innerHTML = `<span class="name h3inside">${formNameVal}</span>`;
                        div.setAttribute('data-name', formNameVal);
                        div.setAttribute('data-message', formMessageVal);
                        div.setAttribute('data-name', formFromNameVal);
                        div.prepend(largeImageSvgCopy);
                        starGridContainer.prepend(div);

                        ToggleStarOverlay();
                    }

                    return true;
                }
            });

            backButton.classList.remove('grey'); // Make "back" button more opaque after first stage
        });
    }

    if (noDonation) {
        noDonation.addEventListener('click', e => {
            e.preventDefault();
            zenscroll.center(allStarSection);
        });
    }

    if (backButton) {
        backButton.addEventListener('click', e => {
            e.preventDefault();
            nextButton.removeAttribute('disabled');
            // eslint-disable-next-line array-callback-return
            formFieldsetsArr.some((fieldset, index) => {
                const moveBackward = () => {
                    zenscroll.center(formSection);

                    if (formSection.classList.contains('final-stage')) {
                        formSection.classList.remove('final-stage');
                    }

                    fieldset.classList.remove('active');

                    if (formStepsArr[activeIndex]) {
                        formStepsArr[activeIndex].classList.remove('active');
                    }

                    if (
                        formWrapper.classList.contains(
                            `fieldset-${activeIndex}-active`,
                        )
                    ) {
                        formWrapper.classList.remove(
                            `fieldset-${activeIndex}-active`,
                        );
                    }

                    if (
                        formSection.classList.contains(
                            `fieldset-${activeIndex}-active_WRAP`,
                        )
                    ) {
                        formSection.classList.remove(
                            `fieldset-${activeIndex}-active_WRAP`,
                        );
                    }

                    if (
                        formButtonsContainer.classList.contains(
                            `buttons-${activeIndex}-active`,
                        )
                    ) {
                        formButtonsContainer.classList.remove(
                            `buttons-${activeIndex}-active`,
                        );
                    }

                    if (formOutputArr[activeIndex]) {
                        formOutputArr[activeIndex].classList.remove('active');
                    }

                    activeIndex = index -= 1;
                    formButtonsContainer.classList.add(
                        `buttons-${activeIndex}-active`,
                    );

                    setTimeout(() => {
                        if (formFieldsetsArr[activeIndex]) {
                            formFieldsetsArr[activeIndex].classList.add(
                                'active',
                            );
                        }

                        if (formStepsArr[activeIndex]) {
                            formStepsArr[activeIndex].classList.add('active');
                        }

                        formWrapper.classList.add(
                            `fieldset-${activeIndex}-active`,
                        );

                        if (formOutputArr[activeIndex]) {
                            formOutputArr[activeIndex].classList.add('active');
                        }
                    }, 500);
                };

                if (
                    fieldset.classList.contains('active')
                    && activeIndex !== 0
                ) {
                    moveBackward();
                    return true;
                }
                if (activeIndex !== formFieldsetsArr.length - 3) {
                    nextButton.innerHTML = '<span class="text">Next</span>';
                }
                if (activeIndex === 1) {
                    // If back button returns user to very first form page
                    backButton.classList.add('grey');
                }
            });
        });
    }

    // "Step 6" (donation CTA) donate button functionality


    const donationSingle = document.getElementById('donation-single');
    const donationMonthly = document.getElementById('donation-monthly');
    const donationAmountButtons = Array.from(
        document.querySelectorAll('[name="donation-amount"]'),
    );
    const donationAmountOther = document.getElementById(
        'donation-amount-other-input',
    );
    const donationDetails = document.querySelector(
        '.donation-details-container',
    );
    let donationType = '';
    let donationLink = '';

    const donationAmountOtherRadio = document.getElementById(
        'donation-amount-other',
    );
    if (checkIfMobile()) {
        donationAmountOtherRadio.checked = true;
        donationAmountOther.value = 10;
    }
    window.addEventListener('resize', () => {
        if (checkIfMobile()) {
            donationAmountOtherRadio.checked = true;
            donationAmountOther.value = 10;
        }
    });

    const toDouble = Array.from(document.querySelectorAll('.toDouble'));
    const doubled = Array.from(document.querySelectorAll('.doubled'));

    const mobileValuesOne = () => {
        if (checkIfMobile()) {
            donationAmountOtherRadio.checked = true;
            donationAmountOther.value = 10;
            donationDetails.setAttribute('display-detail', 'other');

            toDouble.forEach(x => {
                const el = x;
                el.innerHTML = `<b>£${donationAmountOther.value}</b>`;
            });
            doubled.forEach(x => {
                const el = x;
                el.innerHTML = `<b>£${
                    Number(donationAmountOther.value) * 2
                }</b>`;
            });

            donationLink = `https://donate.bcrt.org.uk/?customamount=${donationAmountOther.value}`;
            donateScroll.setAttribute('href', donationLink);
        }
    };

    mobileValuesOne();

    window.addEventListener('resize', () => {
        mobileValuesOne();
    });

    donationAmountOther.addEventListener('input', e => {
        toDouble.forEach(x => (x.innerHTML = `<b>£${e.target.value}</b>`));
        doubled.forEach(
            x => (x.innerHTML = `<b>£${Number(e.target.value) * 2}</b>`),
        );
    });

    const changeDonation = () => {
        const checkedDonationInput = donationAmountButtons.filter(
            donationAmountInput => {
                if (donationAmountInput.checked) return true;
                return false;
            },
        )[0];
        const donationAmount = checkedDonationInput.value === 'other'
            ? donationAmountOther.value
            : checkedDonationInput.value;
        donationType = donationSingle.checked
            ? 'CC'
            : donationMonthly.checked
                ? 'DD'
                : '';
        // donationLink = `https://www.committedgiving.uk.net/bcrt/shineastar/Don${donationType}.aspx?amount=${donationAmount}`;
        donationLink = `https://donate.bcrt.org.uk/?customamount=${donationAmount}`;
        donateScroll.setAttribute('href', donationLink);

        if (donationSingle.checked) {
            donationDetails.classList.add('single');
            donationDetails.classList.remove('monthly');
        } else if (donationMonthly.checked) {
            donationDetails.classList.remove('single');
            donationDetails.classList.add('monthly');
        }
    };

    const changeDetailDisplay = index => {
        switch (index) {
        case 0:
            donationDetails.setAttribute('display-detail', 'first');
            break;
        case 1:
            donationDetails.setAttribute('display-detail', 'second');
            break;
        case 2:
            donationDetails.setAttribute('display-detail', 'third');
            break;
        case 3:
            donationDetails.setAttribute('display-detail', 'other');
            break;
        default:
            break;
        }
    };

    donationAmountButtons.forEach((donationAmount, index) => {
        donationAmount.addEventListener('input', () => {
            changeDonation();
            changeDetailDisplay(index);
        });
    });

    donationSingle.addEventListener('click', () => {
        changeDonation();
    });

    donationMonthly.addEventListener('click', () => {
        changeDonation();
    });

    donationAmountOther.addEventListener('change', () => {
        changeDonation();
    });

    // Largely similar functionality to above, for the stand-alone donate form at bottom..
    // ..should probably be refactored into one function time permitting
    const donateNow = document.getElementById('donateNow');
    const donationSingleTwo = document.getElementById('donation-single-two');
    const donationMonthlyTwo = document.getElementById('donation-monthly-two');
    const donationAmountButtons2 = Array.from(
        document.querySelectorAll('[name="donation-amount-2"]'),
    );
    const donationAmountOther2 = document.getElementById(
        'donation-amount2-other-input',
    );
    const donationDetails2 = document.querySelector(
        '.donation-details2-container',
    );

    const donationAmountOther2Radio = document.getElementById(
        'donation-amount2-other',
    );

    const toDouble2 = Array.from(document.querySelectorAll('.toDouble2'));
    const doubled2 = Array.from(document.querySelectorAll('.doubled2'));

    const mobileValues = () => {
        if (checkIfMobile()) {
            donationAmountOther2Radio.checked = true;
            donationAmountOther2.value = 30;
            donationDetails2.setAttribute('display-detail', 'other');

            toDouble2.forEach(
                x => (x.innerHTML = `<b>£${donationAmountOther2.value}</b>`),
            );
            doubled2.forEach(
                x => (x.innerHTML = `<b>£${
                    Number(donationAmountOther2.value) * 2
                }</b>`),
            );

            donationLink = `https://donate.bcrt.org.uk/?customamount=${donationAmountOther2.value}`;
            donateNow.setAttribute('href', donationLink);
        }
    };

    mobileValues();

    window.addEventListener('resize', () => {
        mobileValues();
    });

    donationAmountOther2.addEventListener('input', e => {
        toDouble2.forEach(x => (x.innerHTML = `<b>£${e.target.value}</b>`));
        doubled2.forEach(
            x => (x.innerHTML = `<b>£${Number(e.target.value) * 2}</b>`),
        );
    });

    const changeDonationTwo = () => {
        const checkedDonationInput = donationAmountButtons2.filter(
            donationAmountInput => {
                if (donationAmountInput.checked) return true;
                return false;
            },
        )[0];

        console.log('checkDonationInput: ', checkedDonationInput);

        const donationAmount = checkedDonationInput.value === 'other'
            ? donationAmountOther2.value
            : checkedDonationInput.value;
        console.log('donationAmount: ', donationAmount);
        donationType = donationSingleTwo.checked
            ? 'CC'
            : donationMonthlyTwo.checked
                ? 'DD'
                : '';
        donationLink = `https://www.committedgiving.uk.net/bcrt/shineastar/Don${donationType}.aspx?amount=${donationAmount}`;
        donateNow.setAttribute('href', donationLink);

        if (donationSingleTwo.checked) {
            donationDetails2.classList.add('single');
            donationDetails2.classList.remove('monthly');
        } else if (donationMonthlyTwo.checked) {
            donationDetails2.classList.remove('single');
            donationDetails2.classList.add('monthly');
        }
    };

    const changeDetailDisplayTwo = index => {
        switch (index) {
        case 0:
            donationDetails2.setAttribute('display-detail', 'first');
            break;
        case 1:
            donationDetails2.setAttribute('display-detail', 'second');
            break;
        case 2:
            donationDetails2.setAttribute('display-detail', 'third');
            break;
        case 3:
            donationDetails2.setAttribute('display-detail', 'other');
            break;
        default:
            break;
        }
    };

    donationAmountButtons2.forEach((donationAmount, index) => {
        donationAmount.addEventListener('input', () => {
            changeDonationTwo();
            changeDetailDisplayTwo(index);
        });
    });

    donationSingleTwo.addEventListener('click', () => {
        changeDonationTwo();
    });

    donationMonthlyTwo.addEventListener('click', () => {
        changeDonationTwo();
    });

    donationAmountOther2.addEventListener('change', () => {
        changeDonationTwo();
    });

    const largeImageImg = document.getElementById('largeImageImg');
    const formSubmit = () => {
        // alert('hello');
        // console.log('formSubmit: e: ', e);
        // e.preventDefault();
        // document.getElementById('shineastarForm').submit();
        const shineastarForm = document.getElementById('shineastarForm');
        const formData = new FormData(shineastarForm);

        const URL = '/sas-data-receiver';
        fetch(URL, {
            method: 'POST',
            body: formData,
        })
            .then(res => {
                // console.log('formData: ', formData);
                // console.log(res);
            })
            .catch(res => {
                // console.error(res);
            });

        largeImageImg.style.display = 'none';
        largeImageSvg.style.display = 'block';
        largeImageForName.style.display = 'block';
    };

    if (donateScroll) {
        donateScroll.addEventListener('click', () => {
            zenscroll.center(largeStarImage, 400, 225);
            formSubmit();
        });
    } else {
        donateScroll.addEventListener('click', () => {
            formSubmit();
        });
    }

    // Set preview images
    const starPreview = document.getElementById('starPreview');
    const allStarPreview = document.getElementById('allStarPreview');

    const userImage = document.getElementById('user_image');
    if (userImage) {
        console.log('userImage: ', userImage);
        userImage.addEventListener('change', () => {
            previewFile();
        });
    }

    const previewFile = () => {
        const file = document.querySelector('input[type=file]').files[0]; // sames as here
        const reader = new FileReader();

        reader.onloadend = function () {
            starPreview.setAttribute('xlink:href', reader.result);
            allStarPreview.setAttribute('xlink:href', reader.result);
        };

        if (file) {
            reader.readAsDataURL(file); // reads the data as a URL
        }
    };

    // Remove image button functionality
    const removeImage = document.getElementById('removeImage');
    const userImageSpan = document.getElementById('user_image_span');
    removeImage.addEventListener('click', e => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to remove this image?')) {
            starPreview.setAttribute('xlink:href', '');
            allStarPreview.setAttribute('xlink:href', '');
            userImageSpan.innerHTML = 'Choose an image';
        }
    });

    // All star section, move left/right controls
    const allStarsRight = document.getElementById('allStarsRight');
    const allStarsLeft = document.getElementById('allStarsLeft');

    const searchStarInput = document.getElementById('searchStarInput');
    searchStarInput.addEventListener('input', e => {
        if (e.target.value === '') {
            transformCount = 0;
            translateX = 0;
        }
    });

    const searchStarSubmit = document.getElementById('searchStarSubmit');
    searchStarSubmit.addEventListener('click', () => {
        transformCount = 0;
        translateX = 0;
    });

    const handle = e => {
        if (document.activeElement === searchStarInput) {
            if (e.key === 'Enter') {
                transformCount = 0;
                translateX = 0;
            }
        }
    };

    window.addEventListener('keydown', e => handle(e), true);

    let scrollEnd = false;
    const allStarsMoveRight = () => {
        transformCount += 1;
        if (checkIfMobile()) {
            translateX = document.body.clientWidth;
        }

        const stars = document.querySelector('.x2020x__stars').querySelectorAll('.x2020x__stars--star');

        if (
            transformCount * translateX + translateX
            < allStarsTransform.clientWidth
        ) {
            allStarsRight.className = 'button-nav right';
            scrollEnd = false;
        } else {
            allStarsRight.className = 'button-nav right disabled';
            scrollEnd = true;
        }

        if (translateX > 0) {
            allStarsLeft.className = 'button-nav left';
        } else {
            allStarsLeft.className = 'button-nav left disabled';
        }

        allStarsTransform.style.transform = `translateX(-${
            translateX * transformCount
        }px)`;
    };

    if (allStarsRight) {
        allStarsRight.addEventListener('click', allStarsMoveRight, false);
    }

    const allStarsMoveLeft = () => {
        transformCount -= 1;
        if (checkIfMobile()) {
            translateX = window.innerWidth;
        }

        if (transformCount >= allStarsTransform.clientWidth - translateX) {
            allStarsRight.className = 'button-nav right disabled';
            scrollEnd = true;
        } else {
            allStarsRight.className = 'button-nav right';
            scrollEnd = false;
        }

        if (transformCount === 0) {
            allStarsLeft.className = 'button-nav left disabled';
        } else {
            allStarsLeft.className = 'button-nav left';
        }
        allStarsTransform.style.transform = `translateX(-${
            translateX * transformCount
        }px)`;
    };

    if (allStarsLeft) {
        allStarsLeft.addEventListener('click', allStarsMoveLeft, false);
    }

    let touchstartX = 0;
    let touchendX = 0;

    function handleSwipe() {
        const xDiff = touchstartX - touchendX;
        if (xDiff >= 30 && !scrollEnd) {
            allStarsMoveRight();
        }
        if (xDiff <= -30 && transformCount > 0) {
            allStarsMoveLeft();
        }
    }

    allStarsTransform.addEventListener(
        'touchstart',
        event => {
            touchstartX = event.changedTouches[0].screenX;
        },
        false,
    );

    allStarsTransform.addEventListener(
        'touchend',
        event => {
            touchendX = event.changedTouches[0].screenX;
            handleSwipe();
        },
        false,
    );

    // All star section set grid length
    // const starGrid = document.querySelectorAll('.x2020x__stars--star').length;
    // starGridContainer.style.width = `${675 + 100 + (300 * starGrid) + (40 * starGrid)}px`;
    // allStarsTransform.style.width = `${675 + 100 + (300 * starGrid) + (40 * starGrid)}px`;
};

export default ShineStarMain;
