export default class Filters {
    constructor(elems) {
        if (!elems) {
            return;
        }

        this.filterToggles = elems,
        this.filterLists = document.querySelectorAll('.content-filter-list');
        this.filterToggles = Array.prototype.slice.call(this.filterToggles);
        this.filterLists = Array.prototype.slice.call(this.filterLists);

        this.init();
    }

    init() {
        this.filterToggles.forEach(toggle => {
            const list = toggle.nextElementSibling;

            toggle.addEventListener('click', () => {
                if (list.classList.contains('active')) {
                    list.classList.remove('active');
                } else {
                    this.filterLists.forEach(list => {
                        if (list.classList.contains('active')) {
                            list.classList.remove('active');
                        }
                    });

                    list.classList.add('active');
                }
            });

            if (window.location.pathname == '/about/meet-the-team/') {
                let filterButtonText = window.location.search.replace('?type=', '');

                if (filterButtonText.length == 0) {
                    filterButtonText = 'All';
                }

                toggle.innerHTML = filterButtonText;
            }

            if (window.location.pathname == '/news/') {
                let filterButtonText = window.location.search.replace('?type=', '');

                if (filterButtonText == '%' || filterButtonText.length == 0) {
                    filterButtonText = 'All';
                }

                toggle.innerHTML = filterButtonText;
            }
        });
    }
}
