/**
 * Star Overlay for mobile form preview star
 *
 *
 * @author Matt Haynes <matt.haynes@joipolloi.com>
 * @package BCRT - Shine a star
 */

const ToggleStarOverlay = () => {
    const showStarOverlay = document.getElementById('showStarOverlay');
    const starOverlay = document.getElementById('starOverlay');
    const starGalleryStar = Array.from(document.querySelectorAll('.x2020x__stars--star'));
    const largeImage = document.getElementById('largeImage');
    if (!showStarOverlay) return;

    showStarOverlay.addEventListener('click', () => {
        starOverlay.classList.add('active');
    });

    starOverlay.addEventListener('click', () => {
        starOverlay.classList.remove('active');
    });

    starGalleryStar.forEach(star => {
        star.addEventListener('click', () => {
            const { name, message, fromname } = star.dataset;

            starOverlay.innerHTML = `
                <div class="starOverlay__Inner">
                    <h3>${name || ''}</h3>
                    <p>${message || ''}</p>
                    <h4>${fromname || ''}</h4>
                </div>
            `;
            starOverlay.classList.add('active');
        });
    });

    largeImage.addEventListener('click', () => {
        const LargeImageHTML = largeImage.querySelector('.backofstar').innerHTML;

        if (LargeImageHTML.length > 0) {
            starOverlay.innerHTML = `
                <div class="starOverlay__Inner">
                    ${LargeImageHTML}
                </div>
            `;
            starOverlay.classList.add('active');
        }
    });
};

export default ToggleStarOverlay;
