/**
 * returns whether or not an element is display block
 * @param  {elem}  elem
 * @return {Boolean}
 */
export function isHidden(elem) {
    if (elem) {
        return (elem.currentStyle) ? elem.currentStyle.display : getComputedStyle(elem, null).display;
    }

    return 'none';
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * stolen from https://davidwalsh.name/javascript-debounce-function
 * @param  {Function} func
 * @param  {Integer} wait
 * @param  {Boolean} immediate
 * @return {Function}
 */
export function debounce(func, wait, immediate) {
    let timeout;

    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
}

export function getWindowWidth() {
    const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    return windowWidth;
}

export function getWindowHeight() {
    const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    return windowHeight;
}

export function getAbsoluteHeight(elem, additionalSpace) {
    // stolen from: http://stackoverflow.com/questions/10787782/full-height-of-a-html-element-div-including-border-padding-and-margin
    const theElem = (typeof elem === 'string') ? document.querySelector(elem) : elem;
    const styles = window.getComputedStyle(theElem);
    const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

    return Math.ceil(theElem.offsetHeight + margin + additionalSpace);
}

/**
 * Utility function to generate an element with a class and optional inner HTML
 * @param  {Element} elem
 * @param  {String} classList
 * @param  {String} inner
 * @return {Element}
 */
export function generateElement(elem, classList, inner = '') {
    const tempElem = document.createElement(elem);

    tempElem.className = classList;
    tempElem.innerHTML = inner;

    return tempElem;
}

/**
 * Make a list of elements tabbable by keyboard
 * And another list of elements un-tabbable
 * @param  {Elements} tabElems
 * @param  {Elements} unTabElems
 */
export function makeTabbable(tabElems, unTabElems) {
    if (tabElems) {
        const tabs = Array.prototype.slice.call(tabElems);

        tabs.forEach(tabElem => {
            tabElem.removeAttribute('tabindex');
        });
    }

    if (unTabElems) {
        const unTabs = Array.prototype.slice.call(unTabElems);

        unTabs.forEach(unTabElem => {
            unTabElem.setAttribute('tabindex', '-1');
        });
    }
}

/**
 * Check form for required fields and return false until they all have values
 * @param  {Element} form
 * @return {Boolean}
 */
export function requiredFieldsCheck(form) {
    let requiredFields = form.querySelectorAll('[required]');
    let firstMissingField = null;
    let missingField = null;

    if (!requiredFields && requiredFields.length <= 0) {
        return;
    }

    requiredFields = Array.prototype.slice.call(requiredFields);

    requiredFields.forEach((requiredField, index) => {
        if (requiredField.value === '' || requiredField.value === 0) {
            if (missingField === null) {
                missingField = index;
            }

            return false;
        } if (requiredField.type === 'checkbox' || requiredField.type === 'radio') {
            if (!requiredField.checked) {
                if (missingField === null) {
                    missingField = index;
                }

                return false;
            }
        } else {
            return true;
        }
    });

    if (missingField !== null) {
        firstMissingField = requiredFields[missingField].getAttribute('id');
        document.getElementById(firstMissingField).focus();
        alert('Please ensure you have completed all required fields.');

        return false;
    }

    return true;
}
