// import baguetteBox from './vendor/baguetteBox';
import { debounce } from './utilities/functions';
import DonateWidget from './components/DonateWidget';
import Accordion from './components/Accordion';
import Filters from './components/Filters';
import MobileMenu from './components/MobileMenu';
import StickyNav from './components/StickyNav';
import PrintAndShare from './components/PrintAndShare';
import RequiredFields from './components/RequiredFields';
import Carousel from './components/Carousel';
import EventApplication from './components/EventApplication';
import ShineStarMain from './shineastar/main';
import CustomLazyLoad from './shineastar/CustomLazyLoad';
import ColumnPaginate from './shineastar/ColumnPaginate';
import StarFilter from './shineastar/StarFilter';
import ToggleStarOverlay from './shineastar/ToggleStarOverlay';
import HandleTabbing from './shineastar/HandleTabbing';
import FormPreventEmojis from './shineastar/FormPreventEmojis';

const donateWidget = new DonateWidget(document.getElementById('donate-widget'));
const accordion = new Accordion(document.querySelector('.content-accordion'));
const filters = new Filters(document.querySelectorAll('.content-filter-list-toggle'));
const mobileMenu = new MobileMenu(document.querySelector('.site-nav-links'));
const stickyNav = new StickyNav(document.getElementById('site-nav'));
const printAndShare = new PrintAndShare(document.querySelector('.social-print'), document.querySelector('.social-twitter.share'), document.querySelector('.social-facebook'));
const carousel = new Carousel(document.getElementById('carousel'));
const eventApplication = new EventApplication(document.querySelector('.event-application-form'));
const confApplication = new EventApplication(document.querySelector('.conference-application-form'));

let pageForms = document.querySelectorAll('form');
pageForms = Array.prototype.slice.call(pageForms);

pageForms.forEach(form => {
    const requiredFields = new RequiredFields(form);
});

const shareStoryType = document.getElementById('formAbout');
const pledgeTypeLabel = document.getElementById('pledge-will-detail');
// const galleryBlock = document.querySelector('.gallery-block');

// if (galleryBlock) {
//     baguetteBox.run('.gallery-block');
// }

if (shareStoryType) {
    const iHad = document.getElementById('i-have-had');
    const theyHad = document.getElementById('they-have-had');
    const lostSomeone = document.getElementById('lost-someone');
    let currentValue = '';

    shareStoryType.addEventListener('change', () => {
        if (currentValue !== shareStoryType.options[shareStoryType.selectedIndex]) {
            currentValue = shareStoryType.options[shareStoryType.selectedIndex].value;

            console.log(currentValue);

            iHad.classList.remove('active');
            theyHad.classList.remove('active');
            lostSomeone.classList.remove('active');

            const newActiveSection = document.getElementById(currentValue);
            let theyHadDobInputs = theyHad.querySelectorAll('.form-field-dob-input');
            let lostSomeoneDobInputs = lostSomeone.querySelectorAll('.form-field-dob-input');

            theyHadDobInputs = Array.prototype.slice.call(theyHadDobInputs);
            lostSomeoneDobInputs = Array.prototype.slice.call(lostSomeoneDobInputs);

            newActiveSection.classList.add('active');

            if (currentValue === 'they-have-had') {
                theyHadDobInputs.forEach(dobInput => {
                    dobInput.setAttribute('required', 'required');
                });

                lostSomeoneDobInputs.forEach(dobInput => {
                    dobInput.removeAttribute('required');
                });
            } else if (currentValue === 'lost-someone') {
                theyHadDobInputs.forEach(dobInput => {
                    dobInput.removeAttribute('required');
                });

                lostSomeoneDobInputs.forEach(dobInput => {
                    dobInput.setAttribute('required', 'required');
                });
            } else {
                theyHadDobInputs.forEach(dobInput => {
                    dobInput.removeAttribute('required');
                });

                lostSomeoneDobInputs.forEach(dobInput => {
                    dobInput.removeAttribute('required');
                });
            }
        }
    });
}

if (pledgeTypeLabel) {
    const pledgeTypes = document.forms['make-legacy-pledge'].elements.formPledgeQuery;

    for (let i = 0; i < pledgeTypes.length; i += 1) {
        pledgeTypes[i].onclick = function () {
            if (this.value === 'already in will') {
                document.getElementById('formPledgeWillTypeSpecific').setAttribute('required', 'required');
                pledgeTypeLabel.classList.remove('field-required-off');
            } else {
                document.getElementById('formPledgeWillTypeSpecific').removeAttribute('required');
                pledgeTypeLabel.classList.add('field-required-off');
            }
        };
    }
}

ShineStarMain();
CustomLazyLoad();
ColumnPaginate();
StarFilter();
ToggleStarOverlay();
HandleTabbing();
FormPreventEmojis();
