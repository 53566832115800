/**
 * Prevent Emoji input into the form fields
 *
 *
 * @author Matt Haynes <matt.haynes@joipolloi.com>
 * @package BCRT - Shine a star
 */

import emojiStrip from 'emoji-strip';

const FormPreventEmojis = () => {
    const shineastarForm = document.getElementById('shineastarForm');
    if (!shineastarForm) return;

    const formInputs = Array.from(shineastarForm.querySelectorAll('.form-section input, .form-section textarea'));

    formInputs.forEach(input => {
        if (input.type === 'file') return;

        input.addEventListener('input', () => {
            // eslint-disable-next-line no-param-reassign
            input.value = emojiStrip(input.value);
        }, false);
    });
};

export default FormPreventEmojis;
