export default class DonateWidget {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.widget = elem;
        this.oneOff = document.getElementById('donate-button-2');
        this.regular = document.getElementById('donate-button-1');
        this.amounts = document.querySelectorAll('.donate-radio');
        this.contents = document.querySelector('.donate-text');
        this.donateTextElems = this.contents.querySelectorAll('.donate-text-custom');
        this.otherAmount = document.getElementById('donate-amount-other');
        this.donateTextAmount = document.querySelector('.donate-text .donate-figure');
        this.regularDonateLink = 'https://donate.bcrt.org.uk/?amount=';
        this.singleDonateLink = 'https://donate.bcrt.org.uk/?amount=';
        this.donateLinkElem = document.querySelector('.donate-feature .donate-link');
        this.amounts = Array.prototype.slice.call(this.amounts);
        this.donateTextElems = Array.prototype.slice.call(this.donateTextElems);
        this.currentAmount = this.amounts[0].value;
        this.currentMethod = 'single';

        this.init();
    }

    init() {
        this.oneOff.addEventListener('click', e => {
            if (this.oneOff.classList.contains('active')) {
                this.oneOff.classList.remove('active');
                this.regular.classList.add('active');
                this.currentMethod = 'regular';
                this.widget.classList.add('active-regular');
                this.widget.classList.remove('active-one');
            } else {
                this.oneOff.classList.add('active');
                this.regular.classList.remove('active');
                this.currentMethod = 'single';
                this.widget.classList.add('active-one');
                this.widget.classList.remove('active-regular');
            }

            this.updateDonationLink();
            this.updateDonationText('donate-radio-4');
        });

        this.regular.addEventListener('click', e => {
            if (this.regular.classList.contains('active')) {
                this.regular.classList.remove('active');
                this.oneOff.classList.add('active');
                this.currentMethod = 'single';
                this.widget.classList.add('active-one');
                this.widget.classList.remove('active-regular');
            } else {
                this.regular.classList.add('active');
                this.oneOff.classList.remove('active');
                this.currentMethod = 'regular';
                this.widget.classList.add('active-regular');
                this.widget.classList.remove('active-one');
            }

            this.updateDonationLink();
            this.updateDonationText('donate-radio-1');
        });

        this.amounts.forEach(amount => {
            amount.addEventListener('click', e => {
                const thisId = (amount.getAttribute('id') === 'donate-radio-7') ? 'donate-amount-other' : amount.getAttribute('id');

                this.currentAmount = amount.value;
                this.updateDonationLink();
                this.updateDonationText(thisId);
            });

            const amountLabel = amount.nextElementSibling;

            amountLabel.addEventListener('mouseover', e => {
                const thisId = (amount.getAttribute('id') === 'donate-radio-7') ? 'donate-amount-other' : amount.getAttribute('id');

                this.updateDonationText(thisId);
            });
        });

        this.otherAmount.addEventListener('input', e => {
            this.currentAmount = this.otherAmount.value;
            this.updateDonationLink();
            this.updateDonationText('donate-amount-other');
        });

        this.otherAmount.addEventListener('focus', () => {
            const radioSibling = document.getElementById('donate-radio-7');

            this.currentAmount = 'other';
            radioSibling.checked = true;
            this.updateDonationText('donate-amount-other');
        });
    }

    updateDonationText(donateTextElem) {
        this.donateTextElems.forEach(donateText => {
            donateText.classList.add('hidden');
        });

        const newDonationText = document.getElementById(`${donateTextElem}-custom-text`);

        newDonationText.classList.remove('hidden');
    }

    updateDonationLink() {
        const donateLink = (this.currentMethod === 'single')
            ? this.singleDonateLink + this.currentAmount
            : this.regularDonateLink + this.currentAmount;
        this.donateLinkElem.setAttribute('href', donateLink);
    }
}
