export default class Accordion {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.accordion = elem;
        this.sectionToggles = this.accordion.querySelectorAll('.content-accordion-toggle'),
        this.sectionContents = this.accordion.querySelectorAll('.content-accordion-text');
        this.sectionToggles = Array.prototype.slice.call(this.sectionToggles);
        this.sectionContents = Array.prototype.slice.call(this.sectionContents);
        this.init();
    }

    init() {
        this.sectionToggles.forEach(sectionToggle => {
            sectionToggle.addEventListener('click', function (e) {
                const sectionContent = sectionToggle.nextElementSibling;

                if (sectionContent) {
                    if (this.classList.contains('active')) {
                        this.classList.remove('active');
                        sectionContent.classList.remove('active');
                        sectionContent.setAttribute('aria-hidden', true);
                        sectionContent.style.height = 0;
                        sectionToggle.setAttribute('aria-selected', false);
                    } else {
                        this.classList.add('active');
                        sectionContent.classList.add('active');
                        sectionContent.setAttribute('aria-hidden', false);
                        sectionContent.style.height = `${sectionContent.getAttribute('data-height')}px`;
                        sectionToggle.setAttribute('aria-selected', true);
                    }
                }
            });
        });

        this.sectionContents.forEach(sectionContent => {
            const sectionHeight = sectionContent.offsetHeight;

            sectionContent.setAttribute('data-height', sectionHeight);
            sectionContent.style.height = 0;
        });
    }
}
