export default class Carousel {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.carousel = elem;
        this.firstSlideImage = document.getElementById('carousel-slide-image-1');
        this.firstSlideContent = document.getElementById('carousel-slide-text-1');
        this.imagesContainer = this.carousel.querySelector('.carousel-slide-images');
        this.contentContainer = this.carousel.querySelector('.carousel-slide-contents');
        this.currentSlide = 1;
        this.imageSlides = this.imagesContainer.querySelectorAll('.carousel-slide');
        this.contentSlides = this.contentContainer.querySelectorAll('.carousel-slide');
        this.totalSlides = this.imageSlides.length;
        this.progressIndicator = this.carousel.querySelector('.carousel-progress');
        this.progressPips = this.progressIndicator.querySelectorAll('span');

        this.imageSlides = Array.prototype.slice.call(this.imageSlides);
        this.contentSlides = Array.prototype.slice.call(this.contentSlides);
        this.progressPips = Array.prototype.slice.call(this.progressPips);

        this.init();
    }

    init() {
        this.firstSlideImage.classList.add('carousel-slide-active');
        this.firstSlideContent.classList.add('carousel-slide-active');
        this.firstSlideContent.setAttribute('aria-hidden', false);

        setInterval(() => {
            this.currentSlide++;

            if (this.currentSlide > this.totalSlides) {
                this.currentSlide = 1;
            }

            // get correct image slide
            this.imageSlides.forEach(imageSlide => {
                imageSlide.classList.remove('carousel-slide-active');

                if (imageSlide.dataset.slide == this.currentSlide) {
                    imageSlide.classList.add('carousel-slide-active');
                }
            });

            // get correct content slide
            this.contentSlides.forEach(contentSlide => {
                const button = contentSlide.querySelector('.link-button.link-button-large');

                if (contentSlide.dataset.slide == this.currentSlide) {
                    contentSlide.classList.remove('carousel-slide-inactive');
                    contentSlide.classList.add('carousel-slide-active');
                    contentSlide.setAttribute('aria-hidden', false);
                    contentSlide.removeAttribute('tabindex');
                    button.removeAttribute('tabindex');
                } else {
                    contentSlide.classList.add('carousel-slide-inactive');
                    contentSlide.classList.remove('carousel-slide-active');
                    contentSlide.setAttribute('aria-hidden', true);
                    contentSlide.setAttribute('tabindex', -1);
                    button.setAttribute('tabindex', -1);
                }
            });

            this.progressPips.forEach(progressPip => {
                progressPip.classList.remove('active');

                if (progressPip.dataset.slide == this.currentSlide) {
                    progressPip.classList.add('active');
                }
            });
        }, 8000);
    }
}
