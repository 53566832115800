/**
 * Star columns filter
 *
 *
 * @author Matt Haynes <matt.haynes@joipolloi.com>
 * @package BCRT - Shine a star
 */

import zenscroll from 'zenscroll';
import detach from './DetachNode';

const StarFilter = () => {
    const shineastarPage = document.getElementById('shineastarPage');
    if (!shineastarPage) return;

    const stars = Array.from(document.querySelectorAll('.x2020x__stars--star'));
    const searchStarInput = document.getElementById('searchStarInput');
    const searchStarSubmit = document.getElementById('searchStarSubmit');
    const allStarsTransform = document.getElementById('allStarsTransform');
    const allStarSection = document.getElementById('allstar-section');
    const currentSearch = document.getElementById('currentSearch');

    const shineastarformNext = document.getElementById('formNext');
    const shineastarallStarsLeft = document.getElementById('allStarsLeft');

    let filtered = false;

    const filterStars = isFiltering => {
        if (isFiltering) {
            filtered = true;
            allStarSection.classList.add('filtered');

            let results = 0;
            // eslint-disable-next-line array-callback-return
            stars.filter(star => {
                const name = star.querySelector('.name');

                if (!name.innerHTML.toUpperCase().includes(searchStarInput.value.toUpperCase())) {
                    // eslint-disable-next-line no-param-reassign
                    star.style.display = 'none';
                    detach(star, true, reattach => {
                        searchStarInput.addEventListener('input', () => {
                            reattach();
                        });
                        searchStarInput.removeEventListener('input', () => {
                            reattach();
                        });
                        shineastarformNext.addEventListener('click', () => {
                            reattach();
                        });
                        shineastarformNext.removeEventListener('click', () => {
                            reattach();
                        });
                    });
                } else {
                    // eslint-disable-next-line no-param-reassign
                    star.style.display = 'block';
                    results += 1;
                }

                if (results === 0) {
                    allStarSection.classList.add('no-results');
                    currentSearch.innerHTML = '';
                } else {
                    allStarSection.classList.remove('no-results');
                    currentSearch.innerHTML = `Results - ${results} for "${searchStarInput.value}"`;
                }
            });
        } else {
            filtered = false;
            allStarSection.classList.remove('filtered');

            stars.forEach((star, index) => {
                if (index > 15) return;

                star.style.display = 'block';
            });
        }

        zenscroll.center(allStarsTransform);
        allStarsTransform.style.transform = 'translateX(0vw)';
    };

    const clearFilter = () => {
        filtered = false;
        allStarSection.classList.remove('filtered');

        stars.forEach((star, index) => {
            if (index > 15) return;

            star.style.display = 'block';
        });

        allStarsTransform.style.transform = 'translateX(0vw)';
        shineastarallStarsLeft.classList.add('disabled');
    };

    searchStarSubmit.addEventListener('click', () => {
        filterStars(true);
    });

    searchStarInput.addEventListener('input', () => {
        if (filtered) {
            filterStars(false);
        }
    });

    searchStarInput.addEventListener('blur', e => {
        if (e.target.value === '') {
            filterStars(false);
        } else {
            filterStars(true);
        }
    });

    const handle = e => {
        if (document.activeElement === searchStarInput) {
            if (e.key === 'Enter') {
                if (e.target.value === '') {
                    filterStars(false);
                } else {
                    filterStars(true);
                }
            }
        }
    };

    window.addEventListener('keydown', e => handle(e), true);

    shineastarformNext.addEventListener('click', () => clearFilter(), true);
};

export default StarFilter;
